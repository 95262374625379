var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.lead.multi_sales_opportunities
      ? _vm.lead.multi_sales_opportunities.length != 0
      : false
  )?_c('v-dialog',{attrs:{"width":"900"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"amber lighten-3 mr-2",attrs:{"elevation":"1","small":"","icon":""},on:{"click":_vm.showModal}},'v-btn',attrs,false),{ ...on, ...tooltipOn }),[_c('v-icon',{attrs:{"small":"","color":"#CC9900"}},[_vm._v(" mdi-trophy ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Este Lead fue creado a partir de una venta exitosa (Click para ver mas). ")])])]}}],null,false,961749962),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Multi Oportunidad ")]),_c('v-spacer'),_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" Lead creado ")]),_c('div',{staticClass:"border-default card auto lead-card-container",class:_vm.userRole === 'marketing' ? 'no-hover-pointer' : '',on:{"click":function($event){return _vm.setActualLead(_vm.lead._id)}}},[_c('lead-card',{attrs:{"lead":_vm.lead,"contactLeadName":_vm.lead.contact_lead_name,"srcBrokerImage":_vm.lead.internal_broker
                ? _vm.brokerImageFormat(_vm.lead.internal_broker)
                : _vm.brokerImageFormat(_vm.lead.internal_admin),"brokerName":_vm.getBrokerName(_vm.lead),"observations":_vm.lead.observations,"observations_detail":_vm.lead.observations_detail,"budget":parseInt(_vm.lead.budget),"currency":_vm.lead.currency,"profilePercentage":_vm._f("percentageFormat")(_vm.lead.profile_percentage)}})],1)]),_c('v-divider',{attrs:{"inset":"","vertical":""}}),_c('v-col',[_c('p',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" Lead original(Venta) ")]),(!_vm.origin._id)?_c('v-skeleton-loader',{staticClass:"my-4",attrs:{"type":"list-item-avatar-two-line,list-item, actions"}}):_c('div',{staticClass:"border-default card auto lead-card-container",class:_vm.userRole === 'marketing' ? 'no-hover-pointer' : '',on:{"click":function($event){return _vm.setActualLead(_vm.origin._id)}}},[_c('lead-card',{attrs:{"lead":_vm.origin,"contactLeadName":_vm.origin.contact_lead_name,"srcBrokerImage":_vm.origin.internal_broker
                ? _vm.brokerImageFormat(_vm.origin.internal_broker)
                : _vm.brokerImageFormat(_vm.origin.internal_admin),"brokerName":_vm.getBrokerName(_vm.origin),"observations":_vm.origin.observations,"observations_detail":_vm.origin.observations_detail,"budget":parseInt(_vm.origin.budget),"currency":_vm.origin.currency,"profilePercentage":_vm._f("percentageFormat")(_vm.origin.profile_percentage)}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")])],1)],1),_c('lead-detail-modal',{ref:"leadDetailModal",attrs:{"page":'kanban',"dialog":_vm.isDialogActive},on:{"setDialog":_vm.setDialog}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }